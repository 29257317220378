import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThankYouPage = () => {
  return (
	  <Layout>
	    <SEO title="Thank You" />
	    <h1>Thank You</h1>
	    <p>Welcome to the thank you page</p>
	    <Link to="/">Go back to the homepage</Link>
	  </Layout>
  )
}

export default ThankYouPage
